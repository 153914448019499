<template>
  <div class="app-container">
    <div class="section1"></div>
    <div class="section2">
      <img src="~@/assets/home/section-2.png" alt="" />
    </div>
    <div class="section3">
      <img src="~@/assets/home/section-3.png" alt="" />
      <div class="card-wrapper">
        <div class="card-item-wrapper">
          <div class="card-item">
            <img src="~@/assets/home/11.png" alt="" />
            <img src="~@/assets/home/card11.png" alt="" />
          </div>
        </div>
        <div class="card-item-wrapper">
          <div class="card-item">
            <img src="~@/assets/home/22.png" alt="" />
            <img src="~@/assets/home/card22.png" alt="" />
          </div>
        </div>
        <div class="card-item-wrapper">
          <div class="card-item">
            <img src="~@/assets/home/33.png" alt="" />
            <img src="~@/assets/home/card33.png" alt="" />
          </div>
        </div>
        <div class="card-item-wrapper">
          <div class="card-item">
            <img src="~@/assets/home/44.png" alt="" />
            <img src="~@/assets/home/card44.png" alt="" />
          </div>
        </div>
        <div class="card-item-wrapper">
          <div class="card-item">
            <img src="~@/assets/home/55.png" alt="" />
            <img src="~@/assets/home/card55.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="section4"></div>
    <div class="section5">
      <img src="~@/assets/home/section-5.png" alt="" />
    </div>
    <div class="section6">
      <img src="~@/assets/home/section-6.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@function vw($px) {
  @return ($px / 1920) * 100vw;
}

.app-container {
  margin-top: 0;
  background: url("~@/assets/home/bg.png") no-repeat center;
  background-size: cover;
//   height: vw(4591);
  .section1 {
    padding-top: vw(938);
  }
  .section2 {
    margin-top: vw(100);
    margin-bottom: vw(178);
    img {
      width: vw(1073);
      height: vw(326);
    }
  }
  .section3 {
    img {
      width: vw(320);
      height: vw(78);
      margin-bottom: vw(70);
    }
    .card-wrapper {
      display: flex;
      flex-wrap: wrap;
      padding: 0 vw(360);
      justify-content: center;
      margin-bottom: vw(150);
      .card-item-wrapper {
        width: 33.3%;
        margin-bottom: vw(30);
        .card-item {
          width: vw(380);
          height: vw(340);
          position: relative;
          transition: all 0.5s ease;
          &:hover img {
            transform: rotateY(180deg);
          }
          img {
            width: vw(380);
            height: vw(340);
            position: absolute;
            left: 0;
            top: 0;
            transition: all 1s ease;
            &:nth-child(2) {
              backface-visibility: hidden;
            }
          }
        }
      }
    }
  }
  .section4 {
    height: vw(637);
    width: 100%;
    background: url("~@/assets/home/section-4.png") no-repeat center;
    background-size: cover;
    margin-bottom: vw(179);
  }
  .section5 {
    img {
      width: vw(1352);
      height: vw(272);
    }
    margin-bottom: vw(179);
  }
  .section6 {
    img {
      width: vw(1526);
      height: vw(574);
    }
    padding-bottom: vw(180);
  }
}
</style>